@import "~easytecq-css/scss/functions";
@import "~easytecq-css/scss/button";
@import "~easytecq-css/scss/input";
@import "~easytecq-css/scss/dialog";
@import "~easytecq-css/components/linkList";
@import "~easytecq-css/components/game";
@import "~easytecq-css/scss/placeholder";
@import "~easytecq-css/components/sectionTitle";
@import "~easytecq-css/components/contentCard";
@import "easytecq-css/components/navPage";
@import "easytecq-css/components/sportsbook";
@import "~easytecq-css/components/profileSection";
@import "easytecq-css/components/carouselCard";
@import "easytecq-css/components/profileSection";
@import "easytecq-css/components/itemList";
@import "easytecq-css/components/actionPing";
@import "~easytecq-css/components/future";
@import "~easytecq-css/components/bettingBlock";
@import "~easytecq-css/components/segmentedButtons";
@import "easytecq-css/components/sportsbook";
@import "~easytecq-css/scss/placeholder";
@import "~easytecq-css/components/odds";
@import "~easytecq-css/components/table";
@import "~easytecq-css/scss/form";




.game-details .details-when {
  display: flex;
  gap: 4px;
}
.eo-segmentedButtons .button > span {
  display: flex;
  gap: 4px;
}
.team-details .team-name {
  display: flex;
  gap: 4px;
}
.eo-odds .odds-details {
  display: flex;
  gap: 4px;
}
a.eo-contentCard .contentCard-details .contentCard-shortText {
  color: var(--color-neutral);
}

.eo-sportsbook-list.m-basic {
  padding-left: var(--space-1_5x);
  padding-right: var(--space-1_5x);
  display: flex;
  flex-direction: column;
}

.demo-wrap {
  margin: 0 auto;
  max-width: 882px;
  display: flex;
  flex-direction: column;
  gap: var(--space-4x);
  align-items: justify;
  padding: var(--space-4x) 0;
}

@media (min-width: 1280px) {
.demo-common-grid {
    padding-left: 0;
    padding-right: 0;
}}
@media (min-width: 768px) {
.demo-common-grid {
    padding-left: var(--space-2_5x);
    padding-right: var(--space-2_5x);
}}

@media (min-width: 480px) {
.demo-common-grid {
    padding-left: var(--space-2x);
    padding-right: var(--space-2x);
}}
.demo-common-grid {
    display: flex;
    flex-direction: column;
    gap: var(--space-8x);
    justify-content: center;
    padding-left: var(--space-1_5x);
    padding-right: var(--space-1_5x);
}
.eo-carouselCard.typ-offer {
  min-height: 280px;
  height: auto;
  width: auto;
}
@media (min-width: 768px) {
  .eo-carouselCard.typ-offer {
  min-height: 360px;
  height: auto;
}}
.read-more, .read-less {
  font-weight: bold;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin: 2px;
}

.read-more::after {
  content: "▼";
  margin-right: 4px;
}

.read-less::after {
  content: "▲";
  margin-right: 4px;
}

.logo-image {
  max-width: 100% !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}


.si-ab-section__main h2 {
  margin: 20px 0;
}

.si-ab-section__main h3 {
  margin: 20px 0 10px 0;
}

.si-ab-section__main p {
  margin: 0 0 10px;
}

.betting-tip-article__author {
  margin-top: -.5rem;
  margin-bottom: -1.5rem;
}

@media (max-width: 1200px) {
  .main-content {
    padding-left: 2vw;
    padding-right: 2vw;
  }
}

.betting-tip-article__cover-image {
  width:100%;
  max-height:600px;
  overflow: hidden;
  position: relative;
}
.betting-tip-article__cover-image img {
  width: 100%;
  object-fit: cover;
  vertical-align: middle;
}

.bookmaker-review__cover-image img {
  width: 100%; 
  object-fit: cover;
  vertical-align: middle;
}

.bookmaker-review__cover-image {
  width:100%;
  max-height:600px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .bookmaker-review__cover-image, .betting-tip-article__cover-image {
    display: flex;
    justify-content: flex-start;
  }
  .bookmaker-review__cover-image {
  }
  .bookmaker-review__cover-image img, .betting-tip-article__cover-image img {
    width: auto;
  }
  .betting-tip-article__cover-image img {
    height: 300px;
  }
  .bookmaker-review__cover-image img {
    height: 200px;
  }
}

.betting-tip-article__content, .section-content {
  p {
    margin-bottom: 20px;
  }
}

.sportsbook-benefit span p{
  margin-bottom: 0px;
}